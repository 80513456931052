import React from "react"
import Img from "gatsby-image"
import { format } from "date-fns"
import ReactMarkdown from "react-markdown"

import Icon from "../Icon"

import "./style.scss"

const PostHero = ({ image, meta, heading }) => {
  const createdAt = format(new Date(meta?.createdAt), "MMMM dd / yyyy")

  const handleSocialClick = (e) => {
    e.preventDefault()
    const url = e?.currentTarget?.href
    const w = 600
    const h = 400

    if (url) {
      window.open(
        url,
        "_blank",
        `
                scrollbars=yes,
                width=${w}, 
                height=${h}, 
                top=${window?.innerHeight / 2 - h / 2}, 
                left=${window?.innerWidth / 2 - w / 2}
            `
      )
    }
  }

  return (
    <div className="post-hero relative bg-bare">
      <div className="post-hero__background w-full h-full absolute top-0 left-0 overflow-hidden" />
      <div className="xl:container xl:mx-auto xl:px-10">
        <div className="post-hero__image relative">
          <Img
            tag="background"
            className="relative"
            fluid={[
              image.lowRes,
              {
                ...image.highRes,
                media: "(min-width: 768px)",
              },
            ]}
            style={{ position: null }}
          />
        </div>
      </div>
      <div className="container mx-auto px-10">
        <div className="grid grid-cols-14">
          <div className="col-span-full xl:col-span-12 xl:col-start-2 py-12 md:py-16 md:pt-12 xl:pb-28 relative">
            <span className="block mb-8 font-bold text-secondary">
              {createdAt}
            </span>
            <h1 className="line-styled-heading max-w-2xl lg:max-w-1/2 relative text-4xl md:text-5xl lg:text-6xl text-black font-extrabold uppercase z-20">
              <ReactMarkdown children={heading} />
            </h1>
            <div className="flex text-boulder mt-8 md:mt-12">
              <span className="hidden md:inline-block">Share article</span>
              {typeof window !== "undefined" && (
                <a
                  href={`https://twitter.com/intent/tweet?url=${window?.location?.href}`}
                  onClick={(e) => handleSocialClick(e)}
                  rel="noopener noreferrer"
                  target="_blank"
                  className="md:ml-4 lg:ml-6 w-6"
                >
                  <Icon name="twitter" />
                  <span className="hidden">Twitter</span>
                </a>
              )}
              {typeof window !== "undefined" && (
                <a
                  href={`https://www.facebook.com/sharer.php?u=${window?.location?.href}`}
                  onClick={(e) => handleSocialClick(e)}
                  rel="noopener noreferrer"
                  target="_blank"
                  className="ml-4 lg:ml-6"
                >
                  <Icon name="facebook" />
                  <span className="hidden">Facebook</span>
                </a>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PostHero
