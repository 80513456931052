import React from "react";

import { processBlogPost } from "../../config/helpers";

import PostCard from "../PostCard";

import "./style.scss";
import NewsForm from "../NewsForm";

const PostBody = ({
  body,
  relatedPosts = [],
  secondBody,
  newsContactForm,
  doYouWishToAddAForm,
}) => {

  return (
    <div className="pt-12 md:py-16 xl:py-28 bg-white relative">
      <div className="container mx-auto relative px-10">
        <div className="grid grid-cols-1 md:grid-cols-14">
          <div
            className={`col-span-full ${
              relatedPosts.length > 0
                ? "lg:col-span-8 xl:col-span-7"
                : "xl:col-span-12"
            } xl:col-start-2`}
          >
            <div
              className="wysiwyg"
              dangerouslySetInnerHTML={{ __html: processBlogPost(body) }}
            />
            {doYouWishToAddAForm ? (
              <>
                <NewsForm {...{ ...newsContactForm[0] }} />
                {secondBody ? (
                  <div
                    className="wysiwyg"
                    dangerouslySetInnerHTML={{
                      __html: processBlogPost(secondBody),
                    }}
                  />
                ) : null}
              </>
            ) : null}
          </div>
          {relatedPosts.length > 0 && (
            <div className="post-body__related-posts hidden lg:block -mt-8 md:col-span-5 xl:col-span-4 md:col-start-10 xl:col-start-10">
              {relatedPosts.map((post) => (
                <PostCard
                  {...{ ...post, backgroundColour: "Grey", className: "mt-8" }}
                />
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PostBody;
