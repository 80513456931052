import React from "react";
import { graphql } from "gatsby";

import Root from "../components/Root";
import Meta from "../components/Meta";
import PostHero from "../components/PostHero";
import PostBody from "../components/PostBody";

export const query = graphql`
  query getPost($id: String, $gameId: String) {
    relatedPosts: allDatoCmsPost(
      filter: { game: { id: { eq: $gameId } }, id: { ne: $id } }
      sort: { fields: meta___createdAt, order: DESC }
      limit: 5
    ) {
      nodes {
        title
        slug
        game {
          id
        }
        excerpt
        heroImage {
          mobile: fluid(
            imgixParams: { auto: "format", w: "360", h: "140", fit: "crop" }
          ) {
            base64
            height
            width
            src
            srcSet
            aspectRatio
          }
          desktop: fluid(
            imgixParams: { auto: "format", w: "360", h: "140", fit: "crop" }
          ) {
            base64
            height
            width
            src
            srcSet
            aspectRatio
          }
        }
        meta {
          createdAt
        }
      }
    }
    post: datoCmsPost(id: { eq: $id }) {
      seo {
        description
        title
        twitterCard
        image {
          url
        }
      }
      meta {
        createdAt
      }
      title
      body
      doYouWishToAddAForm
      newsContactForm {
        title
        subjects {
          label
          value
        }
      }
      secondBody
      game {
        id
      }
      heroImage {
        lowRes: fluid(
          imgixParams: { auto: "format", w: "768", h: "400", fit: "crop" }
        ) {
          base64
          height
          width
          src
          srcSet
          aspectRatio
        }
        highRes: fluid(
          imgixParams: { auto: "format", w: "1920", h: "640", fit: "crop" }
        ) {
          base64
          height
          width
          src
          srcSet
          aspectRatio
        }
      }
      showSidebar
      showSocials
    }
  }
`;

const PostTemplate = ({ data }) => {
  const { nodes: relatedPosts } = data?.relatedPosts || [];
  const {
    title,
    body,
    heroImage,
    game,
    meta,
    secondBody,
    newsContactForm,
    doYouWishToAddAForm,
    ...post
  } = data?.post || [];
  const seo = post?.seo;
  return (
    <Root>
      <Meta {...{ seo }} />
      <PostHero {...{ image: heroImage, heading: title, meta }} />
      <PostBody
        {...{
          body,
          secondBody,
          doYouWishToAddAForm,
          newsContactForm,
          game,
          relatedPosts,
        }}
      />
    </Root>
  );
};

export default PostTemplate;
